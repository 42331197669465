<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">日志</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="操作人">
					<a-input v-model="query.do_admin_name" placeholder="操作人" />
				</a-form-model-item>
				<a-form-model-item label="内容">
					<a-input v-model="query.key" placeholder="内容" />
				</a-form-model-item>
				<a-form-model-item label="开始时间">
					<a-date-picker v-model="query.begin_time" @change="beginChange">
					</a-date-picker>
				</a-form-model-item>
				<a-form-model-item label="结束时间">
					<a-date-picker v-model="query.end_time" @change="endChange">
					</a-date-picker>
				</a-form-model-item>
			</a-row>
		</a-form-model>

		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px" v-viewer>

		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'
import { get } from 'js-cookie'
import { type } from 'os'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10,
				begin_time: this.getTime(1),
				end_time: this.getTime(0)
			},
			pagetotal: 0,
			dateFormat: 'yyyy-mm-dd',
			checkValue: [],
			data: [],
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1,
				width: "10%"
			},
			{
				title: '时间',
				dataIndex: 'create_at_str',
				width: "20%"
			},
			{
				title: '操作人',
				dataIndex: 'do_admin_name',
				width: "20%"
			},
			{
				title: '日志',
				dataIndex: 'msg',
				width: "50%"
			}
			],
		}
	},
	created() {
		this.getList()
	},
	methods: {
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
				begin_time: this.getTime(1),
				end_time: this.getTime(0)
			}
			this.getList()
		},
		getList() {
			console.log('shijian', this.getTime(1))
			this.$post(domain + '/admin/v1/system/logList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		beginChange(date, dateString) {
			this.query.begin_time = dateString
		},
		endChange(date, dateString) {
			this.query.end_time = dateString
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.getList();
		},
		getTime(time) {
			let t = new Date()
			t.setMonth(t.getMonth() - time)
			t.toLocaleDateString()
			return t.getFullYear() + '-' + (t.getMonth() + 1) + '-' + t.getDate();
		},
		handleEndOpenChange(o, t) {
			console.log('111111111', o)
			console.log('222222222', t)
			this.getList();
		}
	}
}
</script>

<style lang="less" scoped>
.img-box {
	overflow: hidden;
	display: inline-block;

	.img-icon {
		transform: translateX(-100px);
		filter: drop-shadow(blue 100px 0)
	}
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
<style lang="less">
.fengmap-box {
	position: relative;
	width: 70vw;
	height: 70vh;
	overflow: hidden;

	#fengmap {
		position: absolute;
		top: 0;
		width: 100%;
		bottom: -50px;
	}
}

.feng-icon-div {
	width: 30px;
	height: 30px;
	overflow: hidden;
	background-size: 100% 100%;
	cursor: pointer;
	border-radius: 50%;
	border: 5px solid #333;
	background-color: #fff;
	box-sizing: content-box;
	margin-left: -5px;
	margin-top: -5px;

	&:hover {
		border-color: #2B6AE0;

		.img-fengmap-icon {
			filter: drop-shadow(#2B6AE0 100px 0);
		}
	}
}

.img-fengmap-icon {
	width: 24px;
	height: 24px;
	margin-left: 3px;
	margin-top: 3px;
	filter: drop-shadow(rgb(255, 0, 0) 100px 0);
	transform: translateX(-100px);
	pointer-events: none;
	user-select: none;
}

.img-fengmap-icon-green {
	width: 24px;
	height: 24px;
	margin-left: 3px;
	margin-top: 3px;
	filter: drop-shadow(rgb(2, 182, 26) 100px 0);
	transform: translateX(-100px);
	pointer-events: none;
	user-select: none;
}
</style>
